import { useState, useEffect } from 'react';

import http from "../../../http";
import './style.scss';

const Contact = () => {
  const [contactData, setContactData] = useState()
  useEffect(async () => {
    const contact = await http.get("/contacts");
    if (contact.data) {
      const [data] = contact.data;
      setContactData(data);
    }
  }, []);

  console.log(contactData);

  function addBreakLines(string, splitString) {
    return string.split(splitString).map((s) => (<span>{s}<br/></span>))
  }

  const {
    contact_heading = '',
    contact_subheading = '',
    contact_CTA = '',
    banner_title = '',
    banner_subtitle = '',
  } = contactData || {};

  return (
    <div className="contact">
      <div className="contact-form">
        <div className="container">
          <div className="row ">
            <div className="col-lg-4">
              <h1>
                {addBreakLines(contact_heading, '\n')}
              </h1>
              <p>{contact_subheading}</p>
            <button>{contact_CTA}</button>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-4">
              <form action="">
                <input type="text" placeholder="Name" />
                <input type="text" placeholder="Email" />
                <input type="text" placeholder="Phone" />
                <input type="text" placeholder="Message" />
                <button>SUBMIT</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="gray-section">
        <div className="container">
          <h2>{addBreakLines(banner_title, '\n')}</h2>
        <h3>{banner_subtitle}</h3>
        </div>
      </div>
    </div>
  )
};

export default Contact;
