import {useEffect, useState} from 'react';
import ReactPlayer from 'react-player';
import http from '../../../../http';
import StripeCheckout from "react-stripe-checkout";
import axios from "axios";

import '../style.scss';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
// Video req
// /access-video/:id
// id = video.id

const VideoDetails = ( { currentVideo, closeModal }) => {
    const history = useHistory();
    const [ownedVideo, setOwnership] = useState(true);
    const [accountType, setUserAccountType] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);
    const [popUpBgValue, setPopUpBg] = useState({});
    const [link, setLink] = useState('');
    // TODO: Spakovati u .ENV i povuci iz njega
    const stripePublicKey = 'pk_test_51Kq1ijC87KaBapQ4gWzvWzROliq1aiAksToZehK9O52GEA8vO0esGX2JfC0zpYSO9GoK5aOB68QHEbGp3iUFCC72001SAU2hNQ';
    const userProfile = JSON.parse(localStorage.getItem('userProfile') || '{}');

    useEffect(() => {
        if(localStorage.getItem('userId')){
        setLoggedIn(true);
        async function getUser(){
            const { data } = await http.get(`/users/${localStorage.getItem('userId')}`,  {
                  headers: {
                    Authorization:
                      `Bearer ${localStorage.getItem('token')}`,
                  }})
                  const owned = data.videos;
                  setOwnership(!!owned.find(item=> item.id === currentVideo.id));
                  setUserAccountType(data.type);
          }
          getUser();
        }
        else{
            setOwnership(false)
            setLoggedIn(false);
        }
    }, []);

    useEffect(() => {
        if(!loggedIn || !ownedVideo){
            setLink(`${currentVideo.trailer.url}`)
        }
        if((loggedIn && ownedVideo) || accountType === "subscription"){
            setLink(currentVideo.video.url)
            async function getVideo(){
                const video = await http.get(`/access-video/${currentVideo.id}`,  {
                      headers: {
                        Authorization:
                          `Bearer ${localStorage.getItem('token')}`,
                      }})
                      console.log(video)

              }
              getVideo()
        }
        setPopUpBg({
            background: `url(${currentVideo.thumbnail.url})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center'
        })
    }, [accountType, ownedVideo, loggedIn]);
    
    const closeVideoPopup = () => {
        closeModal()
    }

    const popUpBg = (url, enable) => {
        console.log('radi')
        if(enable){
        return {
            background: `url(${url})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center'
        }
        }
        else{
            return{
                background: `#fff`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center'
            }
        }
    }

    const pay = async (token, video) => {
        try {
            const response = await axios.post(
                'https://ttm-dash.enes.codes/pay',
                {
                    amount: 1000,
                    token,
                    video,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    }
                }
            );

            if (!response.data.error) {
                const { data } = await http.get(`/users/${localStorage.getItem('userId')}`,  {
                    headers: {
                      Authorization:
                        `Bearer ${localStorage.getItem('token')}`,
                    }})
                    const owned = data.videos;
                    setOwnership(!!owned.find(item=> item.id === currentVideo.id));
                    setUserAccountType(data.type);
                console.log('Payment successfully processed!');
            } else {
                // TODO: handle error using message I logged below
                console.log(response.data.error.raw.message);
            }
        } catch (e) {
            console.log(e);
        }
    }
    const goToLogin = () =>{
        history.push("/login");
    }
        return (
            <div className="videos">
            {currentVideo.id &&
                            <div class="vpp-layer-1">
                                <div class="vpp-group-2" style={popUpBgValue}>
                                    {/* TODO: Replace trailer url with video in case that user has access or subscribed fetching url from BE /access-video route */}
                                    <div className='player-wrapper'>
                                    <ReactPlayer url={link} controls light
                                                  width='100%'
                                                  onStart = {() => setPopUpBg({background: 'none'})}
                                                   playing/>
                                                  </div>
                                    <div class="vpp-group-3" onClick={() => closeVideoPopup()}>
                                        <img class="vpp-group-4" src="/assets/vpp/group.png" alt="" width="22"
                                             height="23"/>
                                    </div>
                                </div>
                                <div class="vpp-group-5">
                                            {!ownedVideo && accountType !== 'subscription' && loggedIn && 
                                            <StripeCheckout
                                                stripeKey={stripePublicKey}
                                                label="Buy"
                                                name="Pay With Credit Card"
                                                billingAddress
                                                shippingAddress
                                                amount={(parseFloat(currentVideo.price) * 100)}
                                                description={currentVideo.title}
                                                token={(token) => pay(token, currentVideo.id)}
                                                customer={userProfile.stripeId}
                                            />
                                        }
                                        {!loggedIn && 
                                           <button onClick={goToLogin} className='fake-buy'>BUY</button>
                                        }
                                        {(!ownedVideo && accountType !== 'subscription') && 
                                        <p class="price"><strong
                                            class="vpp-fw700">${currentVideo.price}</strong>
                                         </p>
                                        }
                                                                            <p class="vpp-text">{currentVideo.title}<br/>
                                    <span className='date'>{format(new Date(currentVideo.publish_date), 'MM.dd.yyyy')}
                                    </span>
                                    </p>
                                    {/* <p class="vpp-text-2"><strong
                                        class="vpp-fw700">30:25</strong> &nbsp;| &nbsp;{currentVideo.category?.name || ''}
                                    </p> */}
                                    <p class="vpp-text-3">
                                        {currentVideo.Description}
                                    </p>
                                        </div>
                            </div>
                    }
                </div>
        )
}

export default VideoDetails;
