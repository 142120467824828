import './style.scss'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SuccessfullPayment = () => {

  return (
    <div className="container">
      <div className="successfull-payment">
          <h1>Payment Successfull</h1>
          <FontAwesomeIcon icon={faCheckCircle}/>
      </div>
    </div>
  )
}

export default SuccessfullPayment;
