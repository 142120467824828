import './style.scss'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FailedPayment = () => {

  return (
    <div className="container">
      <div className="failed-payment">
          <h1>Payment Failed</h1>
          <FontAwesomeIcon icon={faExclamationCircle}/>
      </div>
    </div>
  )
}

export default FailedPayment;
