import { useState, useEffect } from 'react';

import http from "../../../http";
import './style.scss';
import {faHome, faUser, faEnvelope, faPhone, faCalendar} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import VideoDetails from '../Videos/VideoDetails';

const doubleInput = {
  width: 'calc(50%)',
};

const Account = () => {
  const history = useHistory();
    const [user, setUser] = useState({})
    const [editUser, setEditUser] = useState({})
    const [editMode, setEditMode] = useState(false)
    const [currentVideo, setCurrentVideo] = useState({})
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        async function getUser(){
          const { data } = await http.get(`/users/${localStorage.getItem('userId')}`,  {
                headers: {
                  Authorization:
                    `Bearer ${localStorage.getItem('token')}`,
                }})
                setUser(data);
        }
        getUser();
      }, []);

      const openVideoPopup = (video) => {
        setCurrentVideo(video);
        setIsVisible(true);
    }
    const closeVideoPopup = () => {
      setCurrentVideo({});
      setIsVisible(false);
  }
      const turnEditModeOn = () =>{
        setEditUser({...user})
        setEditMode(true)
      }
      const turnEditModeOff = () =>{
        setEditMode(false)
      }
      const saveUser = async () =>{
        setEditMode(false);
        const { data } = await http.put(`/users/${localStorage.getItem('userId')}`, editUser, {
            headers: {
              Authorization:
                `Bearer ${localStorage.getItem('token')}`,
            }})
            setUser(data);
      }
      const handleChange = (e) => {
        console.log(e.target.value)
        console.log(e.target.name)
        let temp = {...editUser};
        temp[e.target.name] = e.target.value;
        setEditUser(temp);
      }
    if(!localStorage.getItem('token')){
        history.push("/")
    }
  return (
    <div className="account">
        <div className="container">
          <div className="row ">
            <div className="col-lg-4 account-info-wrapper">
            <div className="account-info-header"><h1>Account</h1> 
            {!editMode &&
                <button onClick={turnEditModeOn}>Edit Profile</button>
            }
            {editMode &&
                <div className="edit-buttons">
                    <button onClick={saveUser}>Save</button>
                    <button onClick={turnEditModeOff}>Cancel</button>
                </div>
            }
            </div>
            {!editMode &&
            <div className='account-info'>
                <div className='account-info-item'><FontAwesomeIcon icon={faUser}/>{user.firstName} {user.lastName}</div>
                <div className='account-info-item'><FontAwesomeIcon icon={faEnvelope}/>{user.email}</div>
                <div className='account-info-item'><FontAwesomeIcon icon={faHome}/>{user.address || '-'}</div>
                <div className='account-info-item'><FontAwesomeIcon icon={faPhone}/>{user.phoneNumber || '-'}</div>
                <div className='account-info-item'><FontAwesomeIcon icon={faCalendar}/>{user.dateOfBirth || '-'}</div>
            </div>
            }
                        {editMode &&
            <div className='account-info'>
                <div className='account-info-item'>
                  <FontAwesomeIcon icon={faUser}/>
                    <input placeholder='First Name' style={doubleInput} name="firstName" type="text" value={editUser.firstName} onInput={handleChange}></input>
                    <input placeholder='Last Name' style={doubleInput} name="lastName" type="text" value={editUser.lastName} onInput={handleChange}></input>
                </div>
                <div className='account-info-item'>
                  <FontAwesomeIcon icon={faEnvelope}/>
                  <input placeholder='Email' name="email" type="text" value={editUser.email} onInput={handleChange}></input>
                </div>
                <div className='account-info-item'>
                  <FontAwesomeIcon icon={faHome}/>
                  <input placeholder='Address' name="address" type="text" value={editUser.address} onInput={handleChange}></input>
                </div>
                <div className='account-info-item'>
                  <FontAwesomeIcon icon={faPhone}/>
                  <input placeholder='Phone Number' name="phoneNumber" type="text" value={editUser.phoneNumber} onInput={handleChange}></input>
                </div>
                <div className='account-info-item'>
                  <FontAwesomeIcon icon={faCalendar}/>
                  <input placeholder='Date of birth' name="dateOfBirth" type="date" value={editUser.dateOfBirth} onInput={handleChange}></input>
                </div>
            </div>
            }
            </div>

            {user.type === 'payment' &&
            <>
            <div className="col-lg-4 account-content">
              {user.type === 'payment' &&
              <div className="account-videos">
                <div className='account-type-header'>
                  <h1>Videos</h1>
                </div>
                <div className="video-wrapper">
                            {user.videos.map(function (video) {
                                return (
                                    <div key={video.id} className="video-item" onClick={() => openVideoPopup(video)}>
                                        <img src={`${video.thumbnail.url}`} alt=""/>
                                    </div>
                                );
                            })}

                        </div>
              </div>
              }
            </div>
            <div className="col-lg-4 account-content">
              {user.type === 'payment' &&
              <div className="account-videos">
                <div className='account-type-header'>
                  <h1>Subscription type <span className='type-header'>Single video</span></h1>
                </div>
              </div>
              }
            </div>
            </>
        }
            <div className="col-lg-4 account-content">
              {user.type === 'subscription' &&
              <div className="account-videos">
                <div className='account-type-header'>
                  <h1>Subscription type <span className='type-header'>Subscriber</span></h1>
                </div>
                <button className='edit-sub'>Manage subscriptions</button>
              </div>
              }
            </div>

          </div>
        </div>






        <div class="modal" style={{display: isVisible ? 'block' : 'none'}}>
                    {currentVideo.id &&
                    <VideoDetails currentVideo={currentVideo} closeModal={closeVideoPopup}/>
                     }
                    </div>
      </div>
  )
};

export default Account;
