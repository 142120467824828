import {useEffect, useState} from 'react';
import ReactPlayer from 'react-player';
import Slider from "react-slick";
import http from '../../../http';
import StripeCheckout from "react-stripe-checkout";
import axios from "axios";
import { format, compareAsc } from 'date-fns'

import './style.scss';
import VideoDetails from './VideoDetails';
import { useHistory } from 'react-router-dom';
// Video req
// /access-video/:id
// id = video.id

const Videos = () => {
    const history = useHistory();
    const [isVisible, setIsVisible] = useState(false);
    const [videos, setVideos] = useState([])
    const [promoVideo, setPromoVideo] = useState({})
    const [currentVideo, setCurrentVideo] = useState({})
    const [userVideos, setUserVideos] = useState([])
    const [accountType, setUserAccountType] = useState('')
    // TODO: Spakovati u .ENV i povuci iz njega
    const stripePublicKey = 'pk_test_51Kq1ijC87KaBapQ4gWzvWzROliq1aiAksToZehK9O52GEA8vO0esGX2JfC0zpYSO9GoK5aOB68QHEbGp3iUFCC72001SAU2hNQ';
    const userProfile = JSON.parse(localStorage.getItem('userProfile') || '{}');

    useEffect(() => {
        async function getProducts() {
            const {data} = await http.get(`/products?_sort=publish_date:DESC`)
            setPromoVideo(data[0])
            setVideos(data.slice(1))
        }
        getProducts();
    }, []);

    const openVideoPopup = (video) => {
        setCurrentVideo(video);
        setIsVisible(true);
    }

    const closeVideoPopup = () => {
        setCurrentVideo({});
        setIsVisible(false);
    }

    const popUpBg = (url) => {
        return {
            background: `url(${url})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat'
        }
    }

    const playVideo = () => {
        // document.getElementsByClassName('react-player__preview')[0].click()
    }

    const pay = async (token, video) => {
        try {
            const response = await axios.post(
                'https://ttm-dash.enes.codes/pay',
                {
                    amount: 1000,
                    token,
                    video,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    }
                }
            );

            if (!response.data.error) {
                // TODO: Handle successful payment
                console.log('Payment successfully processed!');
            } else {
                // TODO: handle error using message I logged below
                console.log(response.data.error.raw.message);
            }
        } catch (e) {
            console.log(e);
        }
    }

    if (videos.length === 0) {
        return (
            <div className='loader-svg'>
            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="46"/>
          </svg>
          </div>
        )
    }
    else {
        return (
            <div className="videos">
                <div class="container">
                    <div className="videos-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <h1>{promoVideo.title}</h1>
                                    <h2 class="text-2"><span class="text-style">{format(new Date(promoVideo.publish_date), 'MM.dd')}</span><span
                                        class="fw400"> {format(new Date(promoVideo.publish_date), '.yyyy')}</span></h2>
                                    <h4>{promoVideo.shortDescription}</h4>
                                </div>
                                <div className="col-lg-6 position-relative promo-thumb-holder">
                                    <img onClick={() => openVideoPopup(promoVideo)} src="/assets/play-button.png"
                                         alt="play-button" className="play-button"/>
                                    <img src={`${promoVideo.thumbnail.url}`} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="videos-container">
                        <h2>Videos</h2>
                        <div className="video-wrapper">
                            {videos.map(function (video) {
                                return (
                                    <div key={video.id} className="video-item" onClick={() => openVideoPopup(video)}>
                                        <img src={`${video.thumbnail.url}`} alt=""/>
                                    </div>
                                );
                            })}

                        </div>
                    </div>

                    <div class="modal" style={{display: isVisible ? 'block' : 'none'}}>
                    {currentVideo.id &&
                    <VideoDetails currentVideo={currentVideo} closeModal={closeVideoPopup}/>
                     }
                    </div>
                </div>
            </div>
        )
    }
}

export default Videos;
