import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import './style.scss'

const Confirmation = () => {
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const type = params.get('type');
  return (
    <div className="login-container">
      <div className="login-form">
        <h1>Confirmation</h1>
        {type === 'register' &&
         <p class="confirmation-text">Your account has been activated successfully. You can now login.</p>
        }
        {type === 'reset' &&
          <p class="confirmation-text">Your password is successfully changed. You can now login.</p>
        }
      </div>
    </div>
  )
}

export default Confirmation;
