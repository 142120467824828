import { useState, useEffect } from 'react';
import { Link, NavLink, useHistory } from "react-router-dom";

import './style.scss';

const Header = () => {
  const [showNabar, setShowNabar] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [type, setType] = useState('');
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    return history.listen((location) => { 
      setType(JSON.parse(localStorage.getItem('currentUser'))?.type)
       if(localStorage.getItem('token')){
         setLoggedIn(true)
       }
       else{
        setLoggedIn(false)
       }
    }) 
 },[history], []) 
 useEffect(() => {
     if(localStorage.getItem('token')){
       setType(JSON.parse(localStorage.getItem('currentUser')).type)
       setLoggedIn(true)
       console.log(type)
       console.log('test')
     }
     else{
      setLoggedIn(false)
     }
},[]) 
 const logout = () =>{
   setLoggedIn(false);
   localStorage.removeItem('token');
   localStorage.removeItem('currentUser');
   localStorage.removeItem('userId');
   localStorage.removeItem('currentUser');
   localStorage.removeItem('userProfile');
   setType('')
 }
  return (<nav className="navbar navbar-expand-lg navbar-light custom-navigation sticky-top">
    <div className="container">
      <a className="navbar-brand" href="/"><img src="/assets/vector_smart_object.png" alt="Thrilled Mama Program"/></a>
      <button onClick={() => {
          setShowNabar(!showNabar)
        }} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"
          style={{ backgroundImage: showNabar ? `url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 252 252' style='enable-background:new 0 0 252 252;' xml:space='preserve'%3e%3cg%3e%3cpath d='M126,0C56.523,0,0,56.523,0,126s56.523,126,126,126s126-56.523,126-126S195.477,0,126,0z M126,234 c-59.551,0-108-48.449-108-108S66.449,18,126,18s108,48.449,108,108S185.551,234,126,234z' fill='%23FF6F69'/%3e%3cpath d='M164.612,87.388c-3.515-3.515-9.213-3.515-12.728,0L126,113.272l-25.885-25.885c-3.515-3.515-9.213-3.515-12.728,0 c-3.515,3.515-3.515,9.213,0,12.728L113.272,126l-25.885,25.885c-3.515,3.515-3.515,9.213,0,12.728 c1.757,1.757,4.061,2.636,6.364,2.636s4.606-0.879,6.364-2.636L126,138.728l25.885,25.885c1.757,1.757,4.061,2.636,6.364,2.636 s4.606-0.879,6.364-2.636c3.515-3.515,3.515-9.213,0-12.728L138.728,126l25.885-25.885 C168.127,96.601,168.127,90.902,164.612,87.388z' fill='%23FF6F69'/%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3c/svg%3e")` :
          `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23FF6F69' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")`
        }}
        ></span>
      </button>

      <div className={`collapse navbar-collapse container ${showNabar
          ? 'show'
          : ''}`} id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
          <li onClick={() => {
              setShowNabar(false)
            }} className="nav-item active">
            <NavLink
              to="/"
              exact
            >
              Home
            </NavLink>
            {/* <Link to="/">Home</Link> */}
          </li>
          <li onClick={() => {
              setShowNabar(false)
            }} className="nav-item">
            <NavLink to="/about">About</NavLink>
          </li>
          <li onClick={() => {
              setShowNabar(false)
            }} className="nav-item">
            <NavLink to="/services">Services</NavLink>
          </li>
          <li onClick={() => {
              setShowNabar(false)
            }} className="nav-item">
            <NavLink to="/contact">Contact</NavLink>
          </li>
          {/* <li onClick={() => {
              setShowNabar(false)
            }} className="nav-item">
            <NavLink to="/videos">Videos</NavLink>
          </li> */}
          <li onClick={() => {
              setShowNabar(false)
            }} className="nav-item subscribe-nav-item">
              {(type ==='payment' || !type) && 
             <NavLink to="/subscribe">Video Library</NavLink>
              }
             {type ==='subscription' && 
             <NavLink to="/videos">Video Library</NavLink>
              }
          </li>
          {!loggedIn &&
          <li onClick={() => {
              setShowNabar(false)
            }} className="nav-item">
            <NavLink to="/login">Login</NavLink>
          </li>
          }
          {loggedIn &&
            <>                    
            <li onClick={() => {
                      setShowNabar(false)
                    }} className="nav-item">
                    <NavLink to="/account">Account</NavLink>
                  </li>
                  <li onClick={() => {
                    logout()
                  }} className="nav-item">
                  <NavLink to="/">Logout</NavLink>
                </li>
            </>
          }     
        </ul>
      </div>
    </div>
  </nav>)
};

export default Header;
