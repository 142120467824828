import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import './style.scss';

const Subscribe = () => {
  const [active, setActive] = useState(1);
  const userProfile = JSON.parse(localStorage.getItem('userProfile') || '{}');

  // TODO: Replace with ENV variables or fetch from our server ?
  const monthlySubscriptionId = 'prod_LXTZslihEhPigh';
  const yearlySubscriptions = 'prod_LXUjXmcKJfXJCP';

  return (
    <div>
      <div className="subscribe-hero">
        <div className="container">
          <h2>Buy or subscribe to video sessions that give you insider tips <br></br> and tricks on inner child healing. <br></br> Each video is practical + actionable so you can <br></br> take what you learn and use it right away.</h2>
          <h1>Choose your track</h1>
        </div>
      </div>
      <div className="subscribe">
        <div className="container">
          <div className="row justify-content-center">
            <div className={`col-lg-3 s-item ${active === 0 ? 'active' : ''}`}>
              <div className="subscribe-item">
                <img src="/assets/bird-2.png" alt="bird" />
                <h2>Single <br/> video</h2>
                <h3>2.99$</h3>
                <p>
                  Looking for advice on a specific topic? Only need one or two videos to help you out?
                  <br/><br/>
                  Choose from a library of videos.
                </p>
                <NavLink to="/videos" style={{textDecoration: 'none'}}><button>Buy</button></NavLink>
              </div>
            </div>
            <div className={`col-lg-3 s-item ${active === 1 ? 'active' : ''}`}>
              <div className="subscribe-item">
                <img src="/assets/bird-2.png" alt="bird" />
                <h2>Monthly Subscription</h2>
                <h3>9.99$</h3>
                <p>
                  Looking for access to a wide variety of videos?
                  Sign up for a monthly subscription and get unlimited access to the video library. Cancel anytime.
                  New videos added regularly!
                </p>
                {/* TODO: Replace this form with axios for more control over flow */}
                <form action="https://ttm-dash.enes.codes/create-checkout-session" method="POST">
                  {/* Add a hidden field with the lookup_key of your Price */}
                  <input type="hidden" name="product" value={monthlySubscriptionId} />
                  <input type="hidden" name="customer" value={userProfile.stripeId} />
                  <button id="checkout-and-portal-button" type="submit">
                    Subscribe
                  </button>
                </form>
              </div>
            </div>
            <div className={`col-lg-3 s-item ${active === 2 ? 'active' : ''}`}>
              <div className="subscribe-item">
                <img src="/assets/bird-2.png" alt="bird" />
                <h2>Yearly Subscription</h2>
                <h3>99.99$</h3>
                <p>
                  Most bang for your buck!
                  Looking for access to a wide variety of videos? Sign up for a yearly subscription and get unlimited access to the video library. <br/>
                  New videos added regularly!
                </p>
                {/* TODO: Replace this form with axios for more control over flow */}
                <form action="https://ttm-dash.enes.codes/create-checkout-session" method="POST">
                  {/* Add a hidden field with the lookup_key of your Price */}
                  <input type="hidden" name="product" value={yearlySubscriptions} />
                  <input type="hidden" name="customer" value={userProfile.stripeId} />
                  <button id="checkout-and-portal-button" type="submit">
                    Subscribe
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="swapper">
            <div onClick={() => setActive(0)} class={`swapper-item ${active === 0 ? 'active' : ''}`}></div>
            <div onClick={() => setActive(1)} class={`swapper-item ${active === 1 ? 'active' : ''}`}></div>
            <div onClick={() => setActive(2)} class={`swapper-item ${active === 2 ? 'active' : ''}`}></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Subscribe;
